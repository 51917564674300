<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 580 : '100%'"
    app

    @input="(val) => $emit('aside', val)"
  >
    <!-- <v-container>
      <a @click="$emit('aside', false)">Back</a>
    </v-container> -->

    <bulk-purchase-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
      @team="team => { distributorId = team.distributor_id }"
    >
      <sync-bulk-pass-form
        :distributor-id="distributorId"
        :selected-ids="bulkPurchase && bulkPurchase.plan_passes ? bulkPurchase.plan_passes.map(p => p.id) : []"
        :sync-base-path="bulkPurchase ? `/admin/bulk-purchases/${bulkPurchase.id}/plan-passes/sync` : ''"
        :sync-able="syncAble"
      />
    </bulk-purchase-form>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeBulkPurchase, updateBulkPurchase } from '@api/product/pass'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import BulkPurchaseForm from './BulkPurchaseForm.vue'
import SyncBulkPassForm from './SyncBulkPassForm.vue'

export default {
  components: { BulkPurchaseForm, SyncBulkPassForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])
    const syncAble = ref(false)
    const distributorId = ref(props.resource?.team?.distributor_id || false)
    const bulkPurchase = ref(props.resource)

    const toggleSync = () => {
      syncAble.value = true
      setTimeout(() => {
        syncAble.value = false
      }, 300)
    }

    const handleSubmit = async form => {
      loading.value = true
      const request = props.resource ? updateBulkPurchase(props.resource.id, { ...form }) : storeBulkPurchase({ ...form })

      await request
        .then(async res => {
          bulkPurchase.value = res.data.data
          toggleSync()
          setTimeout(() => emit('changed', res.data.data))
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,
      bulkPurchase,

      syncAble,
      distributorId,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
