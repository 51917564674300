<template>
  <div>
    <v-form>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="form.sync_ids"
                :items="passesOptions"
                item-text="title"
                item-value="value"
                label="Select Bulk Purchasable Pass"
                outlined
                chips
                multiple
                hide-details
                :disabled="!distributorId"
                @change="handleSync"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-alert
          v-for="msg in errors"
          :key="msg"
          color="warning"
        >
          {{ msg }}
        </v-alert>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import productStore from '@/modules/product/store'
import { t } from '@/plugins/i18n'
import axios from '@axios'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /admin/products/1/sync-passes
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
    distributorId: {
      type: [Number, Boolean],
      default: false,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    const passOption = pass => ({ title: `${t(pass.title)} (${pass.distributor.slug})`, value: pass.id })
    const passFilter = pass => props.distributorId && props.distributorId === pass.distributor_id && pass.bulk

    const passesOptions = computed(() => productStore.state.passes.filter(passFilter).map(passOption))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
        emit('change')

        return Promise.resolve(res)
      })
    }

    const made = async resource => {
      console.log('made resource', resource)

      form.value.sync_ids.push(resource.id)
      await handleSync()
    }

    return {
      form,

      passesOptions,
      handleSync,

      made,
    }
  },
}
</script>
