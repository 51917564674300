<template>
  <div>
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadBulkPurchases"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null,'bulk')"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        v-model="tableSelectedData"
        :headers="tableColumns"
        :items="tableList"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <!-- team -->
        <template #[`item.team_id`]="{item}">
          {{ item.team.nicename }}
        </template>

        <!-- team -->
        <template #[`item.plan_passes`]="{item}">
          <v-chip
            v-for="pass in item.plan_passes"
            :key="pass.id"
          >
            {{ t(pass.title) }}
            ({{ pass.distributor.slug }})
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item, 'bulk')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="deleteBulkPurchase(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <div class="mt-2">
      Create teams a list of passes pointing to the SKUs which have bulk-discount prices
      (min_quantity>1)
    </div>

    <bulk-purchase-aside
      v-if="aside === 'bulk'"
      v-model="asideActive"
      :resource="resource"
      @aside="val => { if (!val) { aside = '' } }"
      @changed="changedBulkPurchase"
    >
    </bulk-purchase-aside>
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'

import BulkPurchaseAside from '../bulk-purchase-resource/BulkPurchaseAside.vue'
import useBulkPurchaseList from './useBulkPurchaseList'

export default {
  components: {
    BulkPurchaseAside,
  },

  setup() {
    const {
      tableList, tableColumns, tableTotal, tableSelectedData, searchQuery, emailQuery, options, loadBulkPurchases, deleteBulkPurchase, loading,
    } = useBulkPurchaseList()

    const asideActive = ref(false)
    const resource = ref(null)
    const aside = ref(null)
    const setAside = (item, type) => {
      asideActive.value = true
      aside.value = type
      resource.value = item
    }

    const changedBulkPurchase = () => {
      loadBulkPurchases()
      aside.value = ''
      asideActive.value = false
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      searchQuery,
      emailQuery,

      loadBulkPurchases,
      deleteBulkPurchase,

      setAside,
      asideActive,
      aside,
      resource,

      options,
      loading,

      t,
      changedBulkPurchase,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
