var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "value": _vm.active,
      "temporary": "",
      "touchless": "",
      "right": !_vm.$vuetify.rtl,
      "width": _vm.$vuetify.breakpoint.smAndUp ? 580 : '100%',
      "app": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('aside', val);
      }
    }
  }, [_c('bulk-purchase-form', {
    attrs: {
      "resource": _vm.resource,
      "loading": _vm.loading,
      "errors": _vm.errors
    },
    on: {
      "submit": _vm.handleSubmit,
      "team": function team(_team) {
        _vm.distributorId = _team.distributor_id;
      }
    }
  }, [_c('sync-bulk-pass-form', {
    attrs: {
      "distributor-id": _vm.distributorId,
      "selected-ids": _vm.bulkPurchase && _vm.bulkPurchase.plan_passes ? _vm.bulkPurchase.plan_passes.map(function (p) {
        return p.id;
      }) : [],
      "sync-base-path": _vm.bulkPurchase ? "/admin/bulk-purchases/".concat(_vm.bulkPurchase.id, "/plan-passes/sync") : '',
      "sync-able": _vm.syncAble
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }