<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-autocomplete
          v-model="form.team_id"
          label="Team"
          item-text="title"
          item-value="value"
          outlined
          :items="teamOptions"
          :rules="[between(form.team_id, 1, 999999999)]"
          @change="changeTeam"
        ></v-autocomplete>

        <v-text-field
          v-model="form.nicename"
          label="Internal Recognizable Name"
          outlined
          class="mt-5"
          :rules="[required]"
        />

        <v-text-field
          v-model="form.slug"
          label="Unique Url Slug"
          outlined
          class="mt-5"
          :rules="[required]"
        />
      </v-card-text>

      <slot></slot>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslationLocales } from '@/components'
import { useTeam } from '@/composables'
import distributorStore from '@/modules/distributor/store'
import { between, required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('team_id', null),
      ...field('nicename', null),
      ...field('slug', null),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return
      const data = { ...form.value }
      emit('submit', data)
    }

    const { teamOptions } = useTeam()

    const changeTeam = id => {
      const team = distributorStore.state.teams.find(t => t.id === id)
      console.log('team:', team, id)
      emit('team', team)
    }

    return {
      form,
      formElem,
      validate,
      required,
      between,

      teamOptions,
      changeTeam,
    }
  },
}
</script>
